body {
    padding-bottom: 76px;
    padding-top: 2em;
}

@media only screen and (min-width: 576px) {
    body {
        padding-top: 76px;
        overflow: auto;
    }
  }